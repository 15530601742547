// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-2-checkout-alternative-tsx": () => import("./../../../src/pages/2checkout-alternative.tsx" /* webpackChunkName: "component---src-pages-2-checkout-alternative-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adyen-alternative-tsx": () => import("./../../../src/pages/adyen-alternative.tsx" /* webpackChunkName: "component---src-pages-adyen-alternative-tsx" */),
  "component---src-pages-affiliate-program-tsx": () => import("./../../../src/pages/affiliate-program.tsx" /* webpackChunkName: "component---src-pages-affiliate-program-tsx" */),
  "component---src-pages-andorra-payment-gateway-tsx": () => import("./../../../src/pages/andorra-payment-gateway.tsx" /* webpackChunkName: "component---src-pages-andorra-payment-gateway-tsx" */),
  "component---src-pages-apple-pay-for-prestashop-tsx": () => import("./../../../src/pages/apple-pay-for-prestashop.tsx" /* webpackChunkName: "component---src-pages-apple-pay-for-prestashop-tsx" */),
  "component---src-pages-apple-pay-for-shopify-tsx": () => import("./../../../src/pages/apple-pay-for-shopify.tsx" /* webpackChunkName: "component---src-pages-apple-pay-for-shopify-tsx" */),
  "component---src-pages-apple-pay-for-wix-tsx": () => import("./../../../src/pages/apple-pay-for-wix.tsx" /* webpackChunkName: "component---src-pages-apple-pay-for-wix-tsx" */),
  "component---src-pages-apple-pay-for-woocommerce-tsx": () => import("./../../../src/pages/apple-pay-for-woocommerce.tsx" /* webpackChunkName: "component---src-pages-apple-pay-for-woocommerce-tsx" */),
  "component---src-pages-bbva-shopify-tsx": () => import("./../../../src/pages/bbva-shopify.tsx" /* webpackChunkName: "component---src-pages-bbva-shopify-tsx" */),
  "component---src-pages-bizum-empresas-tsx": () => import("./../../../src/pages/bizum-empresas.tsx" /* webpackChunkName: "component---src-pages-bizum-empresas-tsx" */),
  "component---src-pages-bizum-for-prestashop-tsx": () => import("./../../../src/pages/bizum-for-prestashop.tsx" /* webpackChunkName: "component---src-pages-bizum-for-prestashop-tsx" */),
  "component---src-pages-bizum-for-shopify-tsx": () => import("./../../../src/pages/bizum-for-shopify.tsx" /* webpackChunkName: "component---src-pages-bizum-for-shopify-tsx" */),
  "component---src-pages-bizum-for-wix-tsx": () => import("./../../../src/pages/bizum-for-wix.tsx" /* webpackChunkName: "component---src-pages-bizum-for-wix-tsx" */),
  "component---src-pages-bizum-for-woocommerce-tsx": () => import("./../../../src/pages/bizum-for-woocommerce.tsx" /* webpackChunkName: "component---src-pages-bizum-for-woocommerce-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bono-cultural-pasarela-de-pago-tsx": () => import("./../../../src/pages/bono-cultural-pasarela-de-pago.tsx" /* webpackChunkName: "component---src-pages-bono-cultural-pasarela-de-pago-tsx" */),
  "component---src-pages-buy-now-pay-later-for-prestashop-tsx": () => import("./../../../src/pages/buy-now-pay-later-for-prestashop.tsx" /* webpackChunkName: "component---src-pages-buy-now-pay-later-for-prestashop-tsx" */),
  "component---src-pages-cofidis-pay-tsx": () => import("./../../../src/pages/cofidis-pay.tsx" /* webpackChunkName: "component---src-pages-cofidis-pay-tsx" */),
  "component---src-pages-cofidis-tsx": () => import("./../../../src/pages/cofidis.tsx" /* webpackChunkName: "component---src-pages-cofidis-tsx" */),
  "component---src-pages-connect-index-tsx": () => import("./../../../src/pages/connect/index.tsx" /* webpackChunkName: "component---src-pages-connect-index-tsx" */),
  "component---src-pages-connect-sem-esp-tsx": () => import("./../../../src/pages/connect/sem-esp.tsx" /* webpackChunkName: "component---src-pages-connect-sem-esp-tsx" */),
  "component---src-pages-contact-sales-tsx": () => import("./../../../src/pages/contact-sales.tsx" /* webpackChunkName: "component---src-pages-contact-sales-tsx" */),
  "component---src-pages-customer-defence-form-tsx": () => import("./../../../src/pages/customer-defence-form.tsx" /* webpackChunkName: "component---src-pages-customer-defence-form-tsx" */),
  "component---src-pages-ecommerce-billing-tsx": () => import("./../../../src/pages/ecommerce-billing.tsx" /* webpackChunkName: "component---src-pages-ecommerce-billing-tsx" */),
  "component---src-pages-es-blog-tsx": () => import("./../../../src/pages/es/blog.tsx" /* webpackChunkName: "component---src-pages-es-blog-tsx" */),
  "component---src-pages-es-cofidis-tsx": () => import("./../../../src/pages/es/cofidis.tsx" /* webpackChunkName: "component---src-pages-es-cofidis-tsx" */),
  "component---src-pages-eurm-tsx": () => import("./../../../src/pages/eurm.tsx" /* webpackChunkName: "component---src-pages-eurm-tsx" */),
  "component---src-pages-features-pay-by-link-tsx": () => import("./../../../src/pages/features/pay-by-link.tsx" /* webpackChunkName: "component---src-pages-features-pay-by-link-tsx" */),
  "component---src-pages-features-payments-orchestration-index-tsx": () => import("./../../../src/pages/features/payments-orchestration/index.tsx" /* webpackChunkName: "component---src-pages-features-payments-orchestration-index-tsx" */),
  "component---src-pages-features-payments-orchestration-sem-esp-tsx": () => import("./../../../src/pages/features/payments-orchestration/sem-esp.tsx" /* webpackChunkName: "component---src-pages-features-payments-orchestration-sem-esp-tsx" */),
  "component---src-pages-features-recurring-payments-tsx": () => import("./../../../src/pages/features/recurring-payments.tsx" /* webpackChunkName: "component---src-pages-features-recurring-payments-tsx" */),
  "component---src-pages-features-subscriptions-tsx": () => import("./../../../src/pages/features/subscriptions.tsx" /* webpackChunkName: "component---src-pages-features-subscriptions-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-features-whatsapp-payments-tsx": () => import("./../../../src/pages/features/whatsapp-payments.tsx" /* webpackChunkName: "component---src-pages-features-whatsapp-payments-tsx" */),
  "component---src-pages-fr-blog-tsx": () => import("./../../../src/pages/fr/blog.tsx" /* webpackChunkName: "component---src-pages-fr-blog-tsx" */),
  "component---src-pages-google-pay-for-prestashop-tsx": () => import("./../../../src/pages/google-pay-for-prestashop.tsx" /* webpackChunkName: "component---src-pages-google-pay-for-prestashop-tsx" */),
  "component---src-pages-google-pay-for-shopify-tsx": () => import("./../../../src/pages/google-pay-for-shopify.tsx" /* webpackChunkName: "component---src-pages-google-pay-for-shopify-tsx" */),
  "component---src-pages-google-pay-for-wix-tsx": () => import("./../../../src/pages/google-pay-for-wix.tsx" /* webpackChunkName: "component---src-pages-google-pay-for-wix-tsx" */),
  "component---src-pages-google-pay-for-woocommerce-tsx": () => import("./../../../src/pages/google-pay-for-woocommerce.tsx" /* webpackChunkName: "component---src-pages-google-pay-for-woocommerce-tsx" */),
  "component---src-pages-inactive-survey-tsx": () => import("./../../../src/pages/inactive-survey.tsx" /* webpackChunkName: "component---src-pages-inactive-survey-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installment-payments-for-shopify-tsx": () => import("./../../../src/pages/installment-payments-for-shopify.tsx" /* webpackChunkName: "component---src-pages-installment-payments-for-shopify-tsx" */),
  "component---src-pages-installment-payments-for-wix-tsx": () => import("./../../../src/pages/installment-payments-for-wix.tsx" /* webpackChunkName: "component---src-pages-installment-payments-for-wix-tsx" */),
  "component---src-pages-installment-payments-for-woocommerce-tsx": () => import("./../../../src/pages/installment-payments-for-woocommerce.tsx" /* webpackChunkName: "component---src-pages-installment-payments-for-woocommerce-tsx" */),
  "component---src-pages-interchange-fee-calculator-tsx": () => import("./../../../src/pages/interchange-fee-calculator.tsx" /* webpackChunkName: "component---src-pages-interchange-fee-calculator-tsx" */),
  "component---src-pages-klarna-alternative-tsx": () => import("./../../../src/pages/klarna-alternative.tsx" /* webpackChunkName: "component---src-pages-klarna-alternative-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-magento-payment-gateway-tsx": () => import("./../../../src/pages/magento-payment-gateway.tsx" /* webpackChunkName: "component---src-pages-magento-payment-gateway-tsx" */),
  "component---src-pages-mollie-alternative-tsx": () => import("./../../../src/pages/mollie-alternative.tsx" /* webpackChunkName: "component---src-pages-mollie-alternative-tsx" */),
  "component---src-pages-monei-pay-accept-payments-dental-practice-tsx": () => import("./../../../src/pages/monei-pay/accept-payments-dental-practice.tsx" /* webpackChunkName: "component---src-pages-monei-pay-accept-payments-dental-practice-tsx" */),
  "component---src-pages-monei-pay-food-truck-pos-system-tsx": () => import("./../../../src/pages/monei-pay/food-truck-pos-system.tsx" /* webpackChunkName: "component---src-pages-monei-pay-food-truck-pos-system-tsx" */),
  "component---src-pages-monei-pay-index-tsx": () => import("./../../../src/pages/monei-pay/index.tsx" /* webpackChunkName: "component---src-pages-monei-pay-index-tsx" */),
  "component---src-pages-monei-pay-legal-billing-software-tsx": () => import("./../../../src/pages/monei-pay/legal-billing-software.tsx" /* webpackChunkName: "component---src-pages-monei-pay-legal-billing-software-tsx" */),
  "component---src-pages-monei-pay-pos-for-freelancers-tsx": () => import("./../../../src/pages/monei-pay/pos-for-freelancers.tsx" /* webpackChunkName: "component---src-pages-monei-pay-pos-for-freelancers-tsx" */),
  "component---src-pages-monei-pay-pos-systems-for-hair-salon-tsx": () => import("./../../../src/pages/monei-pay/pos-systems-for-hair-salon.tsx" /* webpackChunkName: "component---src-pages-monei-pay-pos-systems-for-hair-salon-tsx" */),
  "component---src-pages-monei-pay-pos-systems-for-restaurants-tsx": () => import("./../../../src/pages/monei-pay/pos-systems-for-restaurants.tsx" /* webpackChunkName: "component---src-pages-monei-pay-pos-systems-for-restaurants-tsx" */),
  "component---src-pages-monei-pay-pos-systems-for-taxis-tsx": () => import("./../../../src/pages/monei-pay/pos-systems-for-taxis.tsx" /* webpackChunkName: "component---src-pages-monei-pay-pos-systems-for-taxis-tsx" */),
  "component---src-pages-monei-pay-qr-code-payment-restaurant-tsx": () => import("./../../../src/pages/monei-pay/qr-code-payment-restaurant.tsx" /* webpackChunkName: "component---src-pages-monei-pay-qr-code-payment-restaurant-tsx" */),
  "component---src-pages-monei-pay-qr-tsx": () => import("./../../../src/pages/monei-pay/qr.tsx" /* webpackChunkName: "component---src-pages-monei-pay-qr-tsx" */),
  "component---src-pages-monei-pay-retail-pos-system-tsx": () => import("./../../../src/pages/monei-pay/retail-POS-system.tsx" /* webpackChunkName: "component---src-pages-monei-pay-retail-pos-system-tsx" */),
  "component---src-pages-monei-pay-sem-esp-tsx": () => import("./../../../src/pages/monei-pay/sem-esp.tsx" /* webpackChunkName: "component---src-pages-monei-pay-sem-esp-tsx" */),
  "component---src-pages-notification-preferences-tsx": () => import("./../../../src/pages/notification-preferences.tsx" /* webpackChunkName: "component---src-pages-notification-preferences-tsx" */),
  "component---src-pages-omnichannel-payments-platform-tsx": () => import("./../../../src/pages/omnichannel-payments-platform.tsx" /* webpackChunkName: "component---src-pages-omnichannel-payments-platform-tsx" */),
  "component---src-pages-online-payments-tsx": () => import("./../../../src/pages/online-payments.tsx" /* webpackChunkName: "component---src-pages-online-payments-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-payment-gateway-france-tsx": () => import("./../../../src/pages/payment-gateway-france.tsx" /* webpackChunkName: "component---src-pages-payment-gateway-france-tsx" */),
  "component---src-pages-payment-gateway-index-tsx": () => import("./../../../src/pages/payment-gateway/index.tsx" /* webpackChunkName: "component---src-pages-payment-gateway-index-tsx" */),
  "component---src-pages-payment-gateway-sem-esp-tsx": () => import("./../../../src/pages/payment-gateway/sem-esp.tsx" /* webpackChunkName: "component---src-pages-payment-gateway-sem-esp-tsx" */),
  "component---src-pages-payment-gateway-spain-tsx": () => import("./../../../src/pages/payment-gateway-spain.tsx" /* webpackChunkName: "component---src-pages-payment-gateway-spain-tsx" */),
  "component---src-pages-payment-methods-apple-pay-tsx": () => import("./../../../src/pages/payment-methods/apple-pay.tsx" /* webpackChunkName: "component---src-pages-payment-methods-apple-pay-tsx" */),
  "component---src-pages-payment-methods-bancontact-tsx": () => import("./../../../src/pages/payment-methods/bancontact.tsx" /* webpackChunkName: "component---src-pages-payment-methods-bancontact-tsx" */),
  "component---src-pages-payment-methods-bizum-tsx": () => import("./../../../src/pages/payment-methods/bizum.tsx" /* webpackChunkName: "component---src-pages-payment-methods-bizum-tsx" */),
  "component---src-pages-payment-methods-click-to-pay-tsx": () => import("./../../../src/pages/payment-methods/click-to-pay.tsx" /* webpackChunkName: "component---src-pages-payment-methods-click-to-pay-tsx" */),
  "component---src-pages-payment-methods-cofidis-tsx": () => import("./../../../src/pages/payment-methods/cofidis.tsx" /* webpackChunkName: "component---src-pages-payment-methods-cofidis-tsx" */),
  "component---src-pages-payment-methods-credit-cards-tsx": () => import("./../../../src/pages/payment-methods/credit-cards.tsx" /* webpackChunkName: "component---src-pages-payment-methods-credit-cards-tsx" */),
  "component---src-pages-payment-methods-giropay-tsx": () => import("./../../../src/pages/payment-methods/giropay.tsx" /* webpackChunkName: "component---src-pages-payment-methods-giropay-tsx" */),
  "component---src-pages-payment-methods-google-pay-tsx": () => import("./../../../src/pages/payment-methods/google-pay.tsx" /* webpackChunkName: "component---src-pages-payment-methods-google-pay-tsx" */),
  "component---src-pages-payment-methods-index-tsx": () => import("./../../../src/pages/payment-methods/index.tsx" /* webpackChunkName: "component---src-pages-payment-methods-index-tsx" */),
  "component---src-pages-payment-methods-mb-way-tsx": () => import("./../../../src/pages/payment-methods/mb-way.tsx" /* webpackChunkName: "component---src-pages-payment-methods-mb-way-tsx" */),
  "component---src-pages-payment-methods-multibanco-tsx": () => import("./../../../src/pages/payment-methods/multibanco.tsx" /* webpackChunkName: "component---src-pages-payment-methods-multibanco-tsx" */),
  "component---src-pages-payment-methods-paypal-tsx": () => import("./../../../src/pages/payment-methods/paypal.tsx" /* webpackChunkName: "component---src-pages-payment-methods-paypal-tsx" */),
  "component---src-pages-payment-methods-sepa-direct-debit-tsx": () => import("./../../../src/pages/payment-methods/sepa-direct-debit.tsx" /* webpackChunkName: "component---src-pages-payment-methods-sepa-direct-debit-tsx" */),
  "component---src-pages-payment-methods-sepa-request-to-pay-tsx": () => import("./../../../src/pages/payment-methods/sepa-request-to-pay.tsx" /* webpackChunkName: "component---src-pages-payment-methods-sepa-request-to-pay-tsx" */),
  "component---src-pages-payment-methods-trustly-tsx": () => import("./../../../src/pages/payment-methods/trustly.tsx" /* webpackChunkName: "component---src-pages-payment-methods-trustly-tsx" */),
  "component---src-pages-payments-glossary-tsx": () => import("./../../../src/pages/payments-glossary.tsx" /* webpackChunkName: "component---src-pages-payments-glossary-tsx" */),
  "component---src-pages-payon-aci-alternative-tsx": () => import("./../../../src/pages/payon-aci-alternative.tsx" /* webpackChunkName: "component---src-pages-payon-aci-alternative-tsx" */),
  "component---src-pages-paypal-alternative-tsx": () => import("./../../../src/pages/paypal-alternative.tsx" /* webpackChunkName: "component---src-pages-paypal-alternative-tsx" */),
  "component---src-pages-paypal-for-prestashop-tsx": () => import("./../../../src/pages/paypal-for-prestashop.tsx" /* webpackChunkName: "component---src-pages-paypal-for-prestashop-tsx" */),
  "component---src-pages-paypal-for-shopify-tsx": () => import("./../../../src/pages/paypal-for-shopify.tsx" /* webpackChunkName: "component---src-pages-paypal-for-shopify-tsx" */),
  "component---src-pages-paypal-for-wix-tsx": () => import("./../../../src/pages/paypal-for-wix.tsx" /* webpackChunkName: "component---src-pages-paypal-for-wix-tsx" */),
  "component---src-pages-paypal-for-woocommerce-tsx": () => import("./../../../src/pages/paypal-for-woocommerce.tsx" /* webpackChunkName: "component---src-pages-paypal-for-woocommerce-tsx" */),
  "component---src-pages-pbc-ft-formulario-oci-tsx": () => import("./../../../src/pages/pbc-ft-formulario-oci.tsx" /* webpackChunkName: "component---src-pages-pbc-ft-formulario-oci-tsx" */),
  "component---src-pages-pci-dss-tsx": () => import("./../../../src/pages/pci-dss.tsx" /* webpackChunkName: "component---src-pages-pci-dss-tsx" */),
  "component---src-pages-prestashop-payment-gateway-tsx": () => import("./../../../src/pages/prestashop-payment-gateway.tsx" /* webpackChunkName: "component---src-pages-prestashop-payment-gateway-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-redsys-alternative-tsx": () => import("./../../../src/pages/redsys-alternative.tsx" /* webpackChunkName: "component---src-pages-redsys-alternative-tsx" */),
  "component---src-pages-sectors-payment-gateway-for-cbd-products-tsx": () => import("./../../../src/pages/sectors/payment-gateway-for-cbd-products.tsx" /* webpackChunkName: "component---src-pages-sectors-payment-gateway-for-cbd-products-tsx" */),
  "component---src-pages-shopify-payment-gateway-tsx": () => import("./../../../src/pages/shopify-payment-gateway.tsx" /* webpackChunkName: "component---src-pages-shopify-payment-gateway-tsx" */),
  "component---src-pages-shopify-payments-alternative-tsx": () => import("./../../../src/pages/shopify-payments-alternative.tsx" /* webpackChunkName: "component---src-pages-shopify-payments-alternative-tsx" */),
  "component---src-pages-sla-tsx": () => import("./../../../src/pages/sla.tsx" /* webpackChunkName: "component---src-pages-sla-tsx" */),
  "component---src-pages-stripe-alternative-tsx": () => import("./../../../src/pages/stripe-alternative.tsx" /* webpackChunkName: "component---src-pages-stripe-alternative-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-pages-support-and-claims-tsx": () => import("./../../../src/pages/support-and-claims.tsx" /* webpackChunkName: "component---src-pages-support-and-claims-tsx" */),
  "component---src-pages-tpv-virtual-tsx": () => import("./../../../src/pages/tpv-virtual.tsx" /* webpackChunkName: "component---src-pages-tpv-virtual-tsx" */),
  "component---src-pages-visa-mastercard-for-prestashop-tsx": () => import("./../../../src/pages/visa-mastercard-for-prestashop.tsx" /* webpackChunkName: "component---src-pages-visa-mastercard-for-prestashop-tsx" */),
  "component---src-pages-visa-mastercard-for-shopify-tsx": () => import("./../../../src/pages/visa-mastercard-for-shopify.tsx" /* webpackChunkName: "component---src-pages-visa-mastercard-for-shopify-tsx" */),
  "component---src-pages-visa-mastercard-for-wix-tsx": () => import("./../../../src/pages/visa-mastercard-for-wix.tsx" /* webpackChunkName: "component---src-pages-visa-mastercard-for-wix-tsx" */),
  "component---src-pages-visa-mastercard-for-woocommerce-tsx": () => import("./../../../src/pages/visa-mastercard-for-woocommerce.tsx" /* webpackChunkName: "component---src-pages-visa-mastercard-for-woocommerce-tsx" */),
  "component---src-pages-wix-payment-gateway-tsx": () => import("./../../../src/pages/wix-payment-gateway.tsx" /* webpackChunkName: "component---src-pages-wix-payment-gateway-tsx" */),
  "component---src-pages-woocommerce-payment-gateway-tsx": () => import("./../../../src/pages/woocommerce-payment-gateway.tsx" /* webpackChunkName: "component---src-pages-woocommerce-payment-gateway-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-page-tsx": () => import("./../../../src/templates/blog-post-page.tsx" /* webpackChunkName: "component---src-templates-blog-post-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-selling-page-tsx": () => import("./../../../src/templates/selling-page.tsx" /* webpackChunkName: "component---src-templates-selling-page-tsx" */)
}

