import {AnchorLink} from 'components/AnchorLink';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {LangSelector} from 'components/LangSelector';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SignUpButton} from 'components/SignUpButton';
import {SubscriptionForm} from 'components/SubscriptionForm';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {colors, links} from 'global.config';
import aws_badge from 'images/badge.svg';
import epc_logo from 'images/epc-logo.svg';
import footer_image from 'images/footer.svg';
import pci_logo from 'images/pci-logo.svg';
import iso_certification from 'images/ISO-logo.png';
import service_provide_badge from 'images/service-provide-badge.png';
import banco_de_es_logo from 'images/banco_de_es_logo.svg';
import React from 'react';
import {RiGithubLine, RiLinkedinLine} from 'react-icons/ri';
import styled from 'styled-components';
import {DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import {DownloadOnAppleStore} from 'components/DownloadOnAppleStore';
import {PrismicPage} from 'components/links/PrismicPage';
import financiado_ue from 'images/financiado_ue.png';
import gobierno_spain from 'images/gobierno_spain.png';
import prtyr from 'images/prtyr.png';

type Props = {
  hideCTA?: boolean;
};

const Container = styled.div`
  font-size: 0.9rem;
  @media (max-width: 768px) {
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 250ms color;
    display: inline-block;

    &:hover {
      color: ${colors.pink};
    }
  }
`;

const Background = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -2deg);
    z-index: -1;
    right: 0;
    left: 0;
    bottom: -50%;
    height: 300px;
    background-image: linear-gradient(40deg, #41efd6 0%, #aab4e9 90%);
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    z-index: -2;
    transform: rotate(-30deg);
    left: 18%;
    height: 500px;
    width: 100%;
    background-image: linear-gradient(70deg, #41efd6 0%, #aab4e9 77%);
  }
`;

const TopFooter = styled.footer`
  border-top: 1px solid rgb(229, 229, 229);
  background: rgb(245, 245, 245);
  padding: 30px 0;
`;

const BottomFooter = styled.div`
  background: #404040;
  color: #cdcdcd;
  padding: 5px 0;

  a:hover {
    color: #ffffff !important;
  }
`;

const FooterContent = styled(Content)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MembersDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
`;

const Legal = styled(FooterContent)`
  padding-bottom: 30px;
  padding-top: 10px;
  font-size: 13px;
`;

const BottomColumn = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const Rocket = styled.div`
  bottom: -35px;
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  left: 65%;
  position: absolute;

  img {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    width: 75%;
    left: 45%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 15px;
    display: block;
    height: 1.2em;

    &:hover {
      color: #ffffff;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const FooterLangSelector = styled(LangSelector)`
  margin-left: 0;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const Badge = styled.div`
  margin-top: 10px;

  a {
    width: 128px;
    height: 128px;
  }

  img {
    width: 100%;
  }
`;

const FooterFirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  @media (max-width: 768px) {
    align-items: center;
    margin: 0;
  }
`;

const FooterColumn = styled.div`
  margin: 18px 15px;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    flex: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 1.2rem;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    text-align: center;
  }
`;

const MembersSection = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  & > * {
    margin: 20px 25px;
  }

  @media (max-width: 1024px) {
    & > * {
      margin: 10px 25px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MembersSectionLogos = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  & > * {
    margin: 20px 25px;
  }

  @media (max-width: 1024px) {
    & > * {
      margin: 10px 25px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

const MembersSectionText = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  width: 400px;

  & > * {
    margin: 20px 25px;
  }

  @media (max-width: 1024px) {
    & > * {
      margin: 10px 25px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Footer: React.FC<Props> = ({hideCTA}) => {
  const {t} = useI18next();

  return (
    <Container style={{overflow: hideCTA ? 'visible' : 'hidden'}} id="footer">
      {!hideCTA && (
        <Background>
          <Content>
            <Section textAlign="center">
              <div>
                <SectionHeader underline>
                  <Trans>Still here?</Trans>
                </SectionHeader>
                <p>
                  <Trans>Try MONEI right now</Trans>
                </p>
                <SectionActions align="center" style={{marginBottom: 100}}>
                  <SignUpButton variant="light">
                    <Trans>Open an Account</Trans>
                  </SignUpButton>
                  <ContactSalesButton />
                </SectionActions>
              </div>
            </Section>
            <Rocket>
              <img src={footer_image} alt={t('Try MONEI now!')} />
            </Rocket>
          </Content>
        </Background>
      )}
      <TopFooter>
        <FooterContent>
          <FooterFirstColumn>
            <FooterLangSelector bottom />
            <Badge>
              <AnchorLink
                href="https://aws.amazon.com/es/blogs/startups/serverless-architecture-powers-moneis-fast-secure-and-scalable-digital-payment-solutions/"
                target="_blank"
                title="Winner of the AWS’ Startup Architecture Challenge">
                <img src={aws_badge} alt="Winner of the AWS’ Startup Architecture Challenge" />
              </AnchorLink>
            </Badge>
          </FooterFirstColumn>
          <FooterColumn>
            <Trans parent="h4">Product</Trans>
            <ul>
              <li>
                <Link to="/pricing/">
                  <Trans>Pricing</Trans>
                </Link>
              </li>
              <li>
                <AnchorLink href={links.support} target="_blank">
                  <Trans>Support</Trans>
                </AnchorLink>
              </li>
              <li>
                <PrismicPage slug="compare-payment-gateways">
                  <Trans>Compare Payment Gateways</Trans>
                </PrismicPage>
              </li>
              <li>
                <AnchorLink
                  target="_blank"
                  href="https://aws.amazon.com/marketplace/pp/microapps-MONEI-Digital-Payment-Simplified/B0886JDCSY"
                  title="Whitelabel payment gateway">
                  <Trans>
                    For Banks & <br />
                    Financial Institutions
                  </Trans>
                </AnchorLink>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <Trans parent="h4">Company</Trans>
            <ul>
              <li>
                <Link to="/blog/">
                  <Trans>Blog</Trans>
                </Link>
              </li>
              <li>
                <AnchorLink target="_blank" href="https://monei.com/page/press/">
                  <Trans>Press</Trans>
                </AnchorLink>
              </li>
              <li>
                <Link to="/partners/">
                  <Trans>Partners</Trans>
                </Link>
              </li>
              <li>
                <PrismicPage slug="we-are-hiring">
                  <Trans>We are hiring!</Trans>
                </PrismicPage>
              </li>
              <li>
                <Link to="/affiliate-program/">
                  <Trans>Affiliate program</Trans>
                </Link>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <Trans parent="h4">Developers</Trans>
            <ul>
              <li>
                <AnchorLink href={links.docs} target="_blank">
                  <Trans>Documentation</Trans>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href={links.api} target="_blank">
                  <Trans>API Reference</Trans>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href={links.gettingStarted} target="_blank">
                  <Trans>Getting started</Trans>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="https://www.notion.so/MONEI-Experts-f67ee72ce90c4e6eb6069ee4cf396b84"
                  target="_blank">
                  <Trans>MONEI Experts</Trans>
                </AnchorLink>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <Trans parent="h4">Legal</Trans>
            <ul>
              <li>
                <Link to="/legal-notice/">
                  <Trans>Legal Notice</Trans>
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy/">
                  <Trans>Privacy Policy</Trans>
                </Link>
              </li>
              <li>
                <Link to="/support-and-claims/">
                  <Trans>Support and Claims</Trans>
                </Link>
              </li>
              <li>
                <Link to="/pci-dss/">
                  <Trans>PCI DSS Level 1</Trans>
                </Link>
              </li>
              <li>
                <Link to="/sla/">
                  <Trans>Service-level Agreement</Trans>
                </Link>
              </li>
              <li>
                <AnchorLink
                  external
                  href="https://whistleblowersoftware.com/secure/9b515d60-cf8e-45ea-bd3b-8f1b94264ad6">
                  <Trans>Complaint Channel</Trans>
                </AnchorLink>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <Trans parent="h4">Join our monthly newsletter</Trans>
            <SubscriptionForm footer />
          </FooterColumn>
        </FooterContent>
        <MembersSection>
          <img src={banco_de_es_logo} alt="Payment Entity License" width={170} />
          <AnchorLink target="_blank" href="https://www.pcisecuritystandards.org/">
            <img src={pci_logo} alt="Members of PCI Security Standards Council" width={150} />
          </AnchorLink>
          <AnchorLink target="_blank" href="https://www.europeanpaymentscouncil.eu/">
            <img src={epc_logo} alt="Members of The European Payments Council" width={150} />
          </AnchorLink>
          <img src={iso_certification} alt="ISO 27001 Certification" width={110} />
          <AnchorLink target="_blank" href="https://www.visa.com/splisting/searchGrsp.do">
            <img
              src={service_provide_badge}
              alt="Visa global registry of service providers"
              width={110}
            />
          </AnchorLink>
          <DownloadOnAppleStore />
          <DownloadOnGooglePlay />
        </MembersSection>
        <MembersSectionLogos>
          <img src={gobierno_spain} alt="Ministry of science and innovation" width={220} />
          <img src={prtyr} alt="Recovery, transformation and resilience plan" width={220} />
          <img src={financiado_ue} alt="Financed by the European Union" width={220} />
        </MembersSectionLogos>
        <MembersSectionText>
          <Trans>
            The project “Development of an open, pan-European and secure digital payment technology”
            has been subsidized by the CDTI.
          </Trans>
        </MembersSectionText>
      </TopFooter>
      <BottomFooter>
        <FooterContent>
          <BottomColumn>&copy; MONEI DIGITAL PAYMENTS SL</BottomColumn>
          <BottomColumn>Powered by VisaNet, Mastercard & AWS</BottomColumn>
          <SocialIcons>
            <AnchorLink href={links.linkedin} title="LinkedIn" target="_blank">
              <RiLinkedinLine size="1.3em" />
            </AnchorLink>
            <AnchorLink href={links.github} title="github" target="_blank">
              <RiGithubLine size="1.3em" />
            </AnchorLink>
          </SocialIcons>
        </FooterContent>
        <Legal>
          MONEI DIGITAL PAYMENTS SL - ESB02660926 Palestina, 1, Entreplanta, 29007, Málaga, Spain
          BORME Registry Details: Registro Mercantil de MÁLAGA T 5998, L 4905, F 54, S 8,H MA158775,
          I/A 1 (14.01.21). MONEI is a Payments Institution regulated by Banco de España with
          license number: 6911, and a Swift member with BIC number: MDIPES22. MONEI is a member of
          the European Payments Council under the SRTP group. MONEI stores funds from merchants in
          omnibus (safeguard) accounts controlled by fully regulated financial institutions.
        </Legal>
      </BottomFooter>
    </Container>
  );
};
