module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"monei","defaultLang":"en-gb","langs":["en-gb","es-es","fr-fr"],"shortenUrlLangs":true,"path":"/preview","previews":false,"omitPrismicScript":true,"pages":[{"type":"Category","match":"/:lang?/blog/:uid/","path":"/blog","component":"/codebuild/output/src4113050743/src/landings/src/templates/blog-category.tsx"},{"type":"Post","match":"/:lang?/blog/:uid/","path":"/blog","component":"/codebuild/output/src4113050743/src/landings/src/templates/blog-post-page.tsx"},{"type":"Page","match":"/:lang?/page/:uid/","path":"/page","component":"/codebuild/output/src4113050743/src/landings/src/templates/page.tsx"},{"type":"Author","match":"/:lang?/author/:uid/","path":"/author","component":"/codebuild/output/src4113050743/src/landings/src/templates/author.tsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es","ca","pt","de","it","fr"],"defaultLanguage":"en","siteUrl":"https://monei.com","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/","getLanguageFromPath":true,"languages":["es","en","fr"]},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"languages":["es","en","fr"]},{"matchPath":"/:lang?/page/:uid","getLanguageFromPath":true,"excludeLanguages":["es","fr"]},{"matchPath":"/:lang?/qr-affiliate","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/:lang?/author/:uid","getLanguageFromPath":true,"languages":["es","en"]},{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHS56QJ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"source":"landing"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
