import React from 'react';
import {FeaturesList, Item, SectionHeader} from '../Styled';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {MenuItemIcon} from '../MenuItemIcon';
import {AnchorLink} from 'components/AnchorLink';
import {InternalPageLink} from 'components/links/Pages';

type Props = {
  itemClick: VoidFunction;
};

export const Integrations: React.FC<Props> = ({itemClick}) => {
  const {language} = useI18next();

  return (
    <div>
      <SectionHeader>
        <Trans>Integrations</Trans>
      </SectionHeader>
      <FeaturesList>
        <Item>
          <MenuItemIcon name="card" />
          <InternalPageLink slug="payment-gateway" activeClassName="active" onClick={itemClick}>
            Payment Gateway
          </InternalPageLink>
        </Item>

        <Item>
          <MenuItemIcon name="ivan" />
          <AnchorLink
            href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/"
            target="_blank">
            <Trans>Hosted Payment Page</Trans>
          </AnchorLink>
        </Item>

        <Item>
          <MenuItemIcon name="fast-checkout" />
          <AnchorLink
            href="https://docs.monei.com/docs/integrations/build-custom-checkout/"
            target="_blank">
            <Trans>Custom Checkout</Trans>
          </AnchorLink>
        </Item>

        <Item>
          <MenuItemIcon name="pay-by-link" />
          <InternalPageLink
            slug="features/pay-by-link"
            activeClassName="active"
            onClick={itemClick}>
            Pay By Link
          </InternalPageLink>
        </Item>

        <Item>
          <MenuItemIcon name="mobile" />
          <InternalPageLink slug="tpv-virtual" activeClassName="active" onClick={itemClick}>
            TPV Virtual
          </InternalPageLink>
        </Item>

        <Item>
          <MenuItemIcon name="whatsapp" />
          <InternalPageLink
            slug="features/whatsapp-payments"
            activeClassName="active"
            onClick={itemClick}>
            <Trans>WhatsApp Payments</Trans>
          </InternalPageLink>
        </Item>

        <Item>
          <MenuItemIcon name="qr-icon" />
          <InternalPageLink slug="monei-pay/qr" activeClassName="active" onClick={itemClick}>
            <Trans>QR Code Payments</Trans>
          </InternalPageLink>
        </Item>

        <Item>
          <MenuItemIcon name="payments-orchestration" />
          <InternalPageLink
            slug="features/payments-orchestration"
            activeClassName="active"
            onClick={itemClick}>
            <Trans>Payments Orchestration</Trans>
          </InternalPageLink>
        </Item>

        <Item>
          <MenuItemIcon name="api-integration" />
          <InternalPageLink slug="connect" activeClassName="active" onClick={itemClick}>
            <Trans>MONEI Connect</Trans>
          </InternalPageLink>
        </Item>
        <Item>
          <MenuItemIcon name="recurring" />
          <InternalPageLink
            slug="features/recurring-payments"
            activeClassName="active"
            onClick={itemClick}>
            <Trans>Recurring Payments</Trans>
          </InternalPageLink>
        </Item>
        <Item>
          <MenuItemIcon name="subscriptions" />
          <InternalPageLink
            slug="features/subscriptions"
            activeClassName="active"
            onClick={itemClick}>
            <Trans>Subscriptions Payment Service</Trans>
          </InternalPageLink>
        </Item>
        {(language === 'es' || language === 'ca') && (
          <Item>
            <MenuItemIcon name="legal" />
            <InternalPageLink
              slug="bono-cultural-pasarela-de-pago"
              activeClassName="active"
              onClick={itemClick}>
              <Trans>Bono Cultural</Trans>
            </InternalPageLink>
          </Item>
        )}
      </FeaturesList>
    </div>
  );
};
